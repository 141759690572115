import {defineStore} from "pinia";
import clientApi from "../api/client"

export const useClientStore = defineStore('client', {
    state: () => {
        return {
            items: []
        }
    },
    getters: {
        getItems: state => state.items
    },
    actions: {
        async getList() {
            const data = await clientApi.getList()
            this.items = data
            return data
        },
        async searchClient(query) {
            const data = await clientApi.search(query)
            this.items = data
            return data
        }
    }
})