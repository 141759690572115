import Config from "../utils/config"
import {useSecurityStore} from "@/stores/security"
import {useAlertStore} from "@/stores/alert"
import router from "../router/index"

export default {
    async getByUsername(username) {
        return new Promise((resolve, reject) => {
            const securityStore = useSecurityStore()
            const alertStore = useAlertStore()
            fetch(`${Config.getApiUrl()}/users/${username}`, {
                headers: {
                    "Authorization": `Bearer ${securityStore.getToken}`,
                    "Content-type": 'application/json'
                },
                method: 'GET'
            }).then(response => {
                if (response.ok) return response.json()
                else if (response.status === 401) {
                    securityStore.logout()
                    router.push({name: "login"})
                    throw {message: 'Votre token vient d\'expirer, veuillez vous reconnecter'}
                }
            }).then(data => {
                if (data) resolve(data)
                else throw {message: 'Aucune données n\'a pu être récupérées'}
            })
            .catch(error => {
                if (error) alertStore.addAlert({state: 'danger', message: error.message})
                securityStore.logout()
                reject(error)
            })
        })
    }
}