<script setup>
import {ref} from "vue"
import {useUserStore} from "@/stores/user"
import {useClientStore} from "@/stores/client"
import {useSecurityStore} from "@/stores/security"
import {useAlertStore} from "@/stores/alert"
import SearchClient from "../components/SearchClientComponent"

import TimerComponent from "../components/TimerComponent.vue"

const alertStore = useAlertStore()
const userStore = useUserStore()
const clientStore = useClientStore()
const securityStore = useSecurityStore()

let currentProject = ref(null)

function selectProject(ev, project) {
  document.querySelectorAll('li').forEach(el => el.classList.remove('active'))
  ev.target?.classList.add('active')
  currentProject.value = project
}

async function logout() {
  await securityStore.logout()
  alertStore.addAlert({state: 'success', message: `À bientôt`})
}

userStore.getUser(sessionStorage.getItem('username')).then(() => {
  clientStore.getList()
})
</script>

<template>
  <header>
    <span>{{ userStore.getCurrentUser.username }}</span>
    <button type="button" @click="logout">se déconnecter</button>
  </header>
  <main>
    <div class="sidebar">
      <SearchClient/>
      <ul class="level_1">
        <li v-for="client in clientStore.getItems" :key="client.id">
          <strong>{{ client.name }}</strong>
          <ul class="level_2">
            <li v-for="project in client.projects.filter(project => project.state !== 2)" :key="project.id" @click="selectProject($event, project)">
              {{ project.name }}
            </li>
          </ul>
          <p class="no-projects" v-if="!client.projects.filter(project => project.state !== 2).length">Aucun projet en cours</p>
        </li>
      </ul>
    </div>
    <TimerComponent :project="currentProject"/>
  </main>
</template>