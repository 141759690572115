import Config from "../utils/config"
import {useSecurityStore} from "@/stores/security"
import {useAlertStore} from "@/stores/alert"

export default {
    async create(data) {
        const securityStore = useSecurityStore()
        const alertStore = useAlertStore()
        return new Promise((resolve, reject) => {
            fetch(`${Config.getApiUrl()}/logs`, {
                headers: {
                    "Authorization" : `Bearer ${securityStore.getToken}`,
                    "Content-type": "application/json"
                },
                body: JSON.stringify(data),
                method: "POST"
            }).then(response => {
                if (response.ok) return response.json()
            }).then(data => {
                resolve(data)
            }).catch(error => {
                alertStore.addAlert({state: 'danger', message: error.message})
                reject(error)
            })
        })
    }
}