<script setup>
import {ref, onMounted} from "vue"
import {useRouter} from "vue-router"
import {useSecurityStore} from "@/stores/security"

const store = useSecurityStore()
const router = useRouter()

const username = ref('')
const password = ref('')
const isLoggedIn = store.isLoggedIn
let errors = ref({})

function resetErrors() {
  errors.value = {}
}

onMounted(() => {
  if (isLoggedIn) router.push({name: 'dashboard'})
})

function login() {
  if (username.value && password.value) {
    store.login(JSON.stringify({username: username.value, password: password.value}))
        .then(() => router.push({name: 'dashboard'}))
        .catch(() => password.value = '')
  } else {
    errors.value = {
      state: "error",
      message: "Veuillez remplir les champs"
    }
  }
}
</script>

<template>
  <div class="login-form">
    <div v-if="errors?.state" :class="{ 'alert-error': errors.state = 'error' }">
      {{ errors.message }}
    </div>

    <form>
      <div class="form-group">
        <label for="username">Nom d'utilisateur</label>
        <input type="text" name="username" id="username" v-model="username" @focus="resetErrors" @keyup.enter="login">
      </div>

      <div class="form-group">
        <label for="password">Mot de passe</label>
        <input type="password" name="password" id="password" v-model="password" @focus="resetErrors" @keyup.enter="login">
      </div>

      <button type="button" @click="login" @keyup.enter="login">Se connecter</button>
    </form>
  </div>

</template>