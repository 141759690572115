import { createRouter, createWebHistory } from "vue-router";
import DashboardView from "../views/DashboardView.vue"
import Login from "../views/LoginView.vue"

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: DashboardView,
      meta: {requireAuth: true}
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    }
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (sessionStorage.getItem('token')) next()
    else next('/login')
  } else next()
})


export default router;
