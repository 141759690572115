import Config from "../utils/config"
import {useSecurityStore} from "@/stores/security"
import {useAlertStore} from "@/stores/alert"

const clientApi = {
    async getList() {
        const securityStore = useSecurityStore()
        const alertStore = useAlertStore()
        return new Promise((resolve, reject) => {
            fetch(`${Config.getApiUrl()}/clients?isArchived=0`, {
                headers: {
                    "Authorization" : `Bearer ${securityStore.getToken}`,
                    "Content-type": "application/json"
                },
                method: "GET"
            }).then(response => {
                if (response.ok) return response.json()
            }).then(data => {
                const clients = data['hydra:member']
                resolve(clients)
            }).catch(error => {
                alertStore.addAlert({state: 'danger', message: error.message})
                reject()
            })
        })
    },
    async search(query) {
        const securityStore = useSecurityStore()
        const alertStore = useAlertStore()
        return new Promise((resolve, reject) => {
            fetch(`${Config.getApiUrl()}/clients?isArchived=0&search_name=${encodeURI(query)}`, {
                headers: {
                    "Authorization" : `Bearer ${securityStore.getToken}`,
                    "Content-type": "application/json"
                },
                method: "GET"
            }).then(response => {
                if (response.ok) return response.json()
            }).then(data => {
                const clients = data['hydra:member']
                resolve(clients)
            }).catch(error => {
                alertStore.addAlert({state: 'danger', message: error.message})
                reject()
            })
        })

    }
}

export default clientApi