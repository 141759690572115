<script setup>
import {useAlertStore} from "@/stores/alert"

const alertStore = useAlertStore()

const vRead = {
  mounted: (el) => {
    const messageId = el.getAttribute('id')
    setTimeout(() => {
      el.style.transform = 'translate(0, -150%)'
      el.addEventListener('transitionend', () => {
        alertStore.removeAlert(messageId)
      })
    }, 3500)
  }
}
</script>

<template>
  <div class="messages-flash">
    <transition-group name="slide-fade" tag="p">
      <p
          v-read
          v-for="(alert) in alertStore.getAlerts"
          :key="alert.uuid"
          :id="alert.uuid"
          class="message-item"
          :class="'alert alert-' + alert.state"
      >
        {{ alert.message }}
      </p>
    </transition-group>
  </div>
</template>

<style lang="scss" scoped>
.messages-flash {
  position: fixed;
  top: 0.6rem;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 260px;
  transform: translate(-50%, 0);
  z-index: 99;
  pointer-events: none;

  .alert {
    margin: 0.5rem 0;
    padding: 0.4rem 0.6rem;
    font-weight: 600;
    text-align: center;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    transition: all 0.3s ease-in-out;

    &.alert-danger{
      color: var(--col-danger-dark);
      background-color: var(--col-danger);
    }

    &.alert-warning {
      color: var(--col-warning-dark);
      background-color: var(--col-warning);
    }

    &.alert-success {
      color: var(--col-success-dark);
      background-color: var(--col-success);
    }
  }

  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translate(-50%, 0);
    opacity: 0;
  }
}
</style>