import {defineStore} from "pinia";
import userApi from "../api/user"

export const useUserStore = defineStore('user', {
    state: () => {
        return {
            user: {}
        }
    },
    getters: {
        getCurrentUser: state => state.user
    },
    actions: {
        async getUser(username) {
            const data = await userApi.getByUsername(username)
            this.user = data
            return data
        }
    }
})