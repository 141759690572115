import ConfigFile from "../../adct-client-config.json"

export default class Config {
    static isProd() {
        return process.env.NODE_ENV === "production"
    }

    static get() {
        return ConfigFile
    }

    static getApiUrl() {
        return Config.isProd() ? Config.get().api_url_prod : Config.get().api_url_dev
    }
}