import {defineStore} from "pinia";
import Utils from "../utils/utils"

export const useAlertStore = defineStore('alert', {
    state: () => {
        return {
            alerts: []
        }
    },
    getters: {
        getAlerts: state => state.alerts
    },
    actions: {
        addAlert(data) {
            this.alerts.push({
                uuid: Utils.generateUUID(),
                state: data.state,
                message: data.message
            })
        },
        removeAlert(alertId) {
            this.alerts = this.alerts.filter(message => message.uuid !== alertId)
        }
    }
})