import {defineStore} from "pinia";
import timeApi from "../api/time"

export const useTimeStore = defineStore('time', {
    actions: {
        async checkIfExist(payload) {
            return await timeApi.getByProjectAndDate(payload.userId, payload.data)
        },
        async addTime(data) {
            return await timeApi.create(data)
        },
        async updateTime(payload) {
            return await timeApi.update(payload.id, payload.data)
        }
    }
})