import Config from "../utils/config"
import {useAlertStore} from "@/stores/alert"

export default {
    async login(user) {
        const alertStore = useAlertStore()
        return new Promise((resolve, reject) => {
            fetch(`${Config.getApiUrl()}/login_check`, {
                headers: {'content-type': 'application/json'},
                method: "POST",
                body: user
            }).then(response => {
                if (response.ok) return response.json()
                else throw {message: 'Nom d\'utilisateur ou mot de passe non valide'}
            }).then(data => {
                if (data?.token) resolve({username: JSON.parse(user).username, token: data.token})
            }).catch(error => {
                alertStore.addAlert({state: 'danger', message: error.message})
                reject(error)
            })
        })
    }
}