<script setup>
import {ref, defineProps} from "vue"
import PlayIconComponent from "../components/icons/PlayIconComponent.vue"
import PauseIconComponent from "../components/icons/PauseIconComponent.vue"
import ResetIconComponent from "../components/icons/ResetIconComponent.vue"
import {useTimeStore} from "@/stores/time"
import {useUserStore} from "@/stores/user"
import {useAlertStore} from "@/stores/alert"
import {useLogStore} from "@/stores/log"

const timeStore = useTimeStore()
const userStore = useUserStore()
const alertStore = useAlertStore()
const logStore = useLogStore()

const props = defineProps({
  project: Object
})

let display = ref('00:00:00')
let displayms = ref('000')
let h = ref(0)
let m = ref(0)
let sec = ref(0)
let ms = ref(0)
let defaultSeconds = ref(0)
let isStarted = ref(false)
let targetTimestamp = ref(0)
let pausedDifference = ref(0)
let calculatedTime = ref(0)
let interval = null

function play() {
  if (interval) {
    clearInterval(interval)
    interval = null
  }
  let nowTimestamp = Date.now()
  let targetTime = defaultSeconds.value
  if (pausedDifference.value) {
    targetTime = pausedDifference.value
    pausedDifference.value = 0
  }
  if (!isStarted.value) targetTimestamp.value = nowTimestamp - targetTime
  interval = setInterval(updateDisplay, 10)
  isStarted.value = true
}
function pause() {
  let nowTimestamp = Date.now()
  clearInterval(interval)
  if(isStarted.value) {
    pausedDifference.value = Math.abs(targetTimestamp.value - nowTimestamp)
    updateDisplay()
  }
  isStarted.value = false
  calculateTime()
}
function reset() {
  pause()
  let nowTimestamp = Date.now()
  targetTimestamp.value = nowTimestamp - defaultSeconds.value
  pausedDifference.value = 0
  display.value = '00:00:00'
  displayms.value = '000'
}

function updateDisplay(targetTime) {
  let nowTimestamp = Date.now()
  if (targetTime !== undefined) targetTimestamp.value = nowTimestamp - targetTime
  const s = Math.abs(Date.now() - targetTimestamp.value) / 1000
  ms.value = Math.floor((s % 1) * 1000)
  sec.value = Math.floor(s % 60)
  m.value = Math.floor(s/60) % 60
  h.value = Math.floor(s/3600) % 24
  let disp = sec.value.toString().padStart(2, '0')
  disp = m.value.toString().padStart(2, '0') + ':' + disp
  disp = h.value.toString().padStart(2, '0') + ':' + disp
  display.value = disp
  displayms.value = ("000" + ms.value).slice(-3)
}

function calculateTime() {
  if (sec.value > 30) m.value++
  calculatedTime.value = h.value + Math.round(m.value / 30) / 2
}

function getDate() {
  const date = new Date()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const strDate = `${date.getFullYear()}-${month.length > 1 ? month : '0' + month}-${day.toString().length > 1 ? day : '0' + day}`

  return `${new Date(strDate).getTime() / 1000}`
}

function getLogDescription(state, response) {
  return {
    user: userStore.getCurrentUser.username,
    action: state,
    entity: "times",
    entityId: response.id,
    entityIri: response['@id'],
    description: `${userStore.getCurrentUser.username} à enrgistré ${response.times}h de temps de travail sur le projet ${props.project.name} (depuis l'app companion)`
  }
}

function addTime() {
  timeStore.checkIfExist({userId: userStore.getCurrentUser.id, data: {projectId: props.project.id, date: getDate()}})
      .then(result => {
        const data = {
          date: getDate(),
          times: calculatedTime.value.toString(),
          user: userStore.getCurrentUser['@id'],
          project: props.project['@id']
        }
        if (result['hydra:totalItems']) {
          data.times = (parseInt(result['hydra:member'][0].times) + calculatedTime.value).toString()
          timeStore.updateTime({id: result['hydra:member'][0].id, data})
              .then(response => {
                alertStore.addAlert({
                  state: 'success',
                  message: `Le temps pour ${props.project.name} à bien été mis à jour avec ${calculatedTime.value.toString()}h`
                })
                logStore.createLog(getLogDescription("UPDATE", response))
                reset()
                calculatedTime.value = 0
              })
        } else timeStore.addTime(data)
            .then((response) => {
              alertStore.addAlert({
                state: 'success',
                message: `${data.times}h à bien été ajouté au temps pour ${props.project.name}`
              })
              logStore.createLog(getLogDescription("CREATE", response))
              reset()
              calculatedTime.value = 0
            })
      })
}
</script>

<template>
  <div class="timer-comp">
    <div v-if="project">
      <h2>{{ project.name }}</h2>
      <div class="counter-container">
        <div class="counter">
          <span>{{ display }}</span><span class="ms">{{ displayms}}</span>
        </div>
        <div class="action-buttons">
          <button @click="play">
            <PlayIconComponent/>
            play
          </button>
          <button @click="pause">
            <PauseIconComponent/>
            pause
          </button>
          <button @click="reset">
            <ResetIconComponent/>
            reset
          </button>
        </div>
      </div>
      <div v-if="calculatedTime > 0" class="send-container">
        <p>Envoyer {{ calculatedTime }}h pour le projet {{ project.name }} ?</p>
        <button class="send" @click="addTime">envoyer le temps</button>
      </div>
    </div>
    <div v-else>
      <p>Veuillez choisir un projet dans la liste</p>
    </div>
  </div>
</template>