import {defineStore} from "pinia";
import securityApi from "../api/security"
import {useAlertStore} from "./alert"
import router from "../router/index"

export const useSecurityStore = defineStore('security', {
    state: () => ({
        token: sessionStorage.getItem('token')
    }),
    getters: {
        isLoggedIn: state => !!state.token,
        getToken: state => state.token
    },
    actions: {
        async login(user) {
            const alertStore = useAlertStore()
            const data = await securityApi.login(user)

            if(data) {
                const token = data.token

                sessionStorage.setItem('token', token)
                sessionStorage.setItem('username', data.username)
                this.token = token

                alertStore.addAlert({state: 'success', message: `Content de vous revoir ${data.username}`})
                router.push({name: 'dashboard'})
            }
        },
        logout() {
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('username')
            this.token = ''
            router.push({name: 'login'})
        }
    }
})