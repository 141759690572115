<script setup>
import {ref, watch} from "vue"
import {useClientStore} from "@/stores/client"
import LoaderComponent from "@/components/items/LoaderComponent"
import SearchIconComponent from "@/components/icons/SearchIconComponent"

const clientStore = useClientStore()

let query = ref('')
let oldValue = ref('')
let loading = ref(false)
let time = ref(0)
let typing = ref(false)

function toggleTyping() {
  time.value = Date.now()
  typing.value = true
}

watch(query, () => {
  if (!query.value) {
    loading.value = true
    clientStore.getList().then(() => {
      loading.value = false
      oldValue.value = ""
    })
  }
})

async function search() {
  if (Date.now() - time.value < 600) await new Promise(resolve => setTimeout(resolve, 600))

  if (query.value && query.value !== oldValue.value) {
    loading.value = true

    clientStore.searchClient(query.value.replace(' ', '%')).then(() => {
      loading.value = false
      oldValue.value = query.value
    })
  }
}

</script>

<template>
  <div class="search-container">
    <SearchIconComponent v-if="!loading" />
    <LoaderComponent :loading="loading" />
    <input type="search" name="query" v-model="query" @keydown="toggleTyping" @keyup="search" placeholder="rechercher par client ou projet">
  </div>
</template>